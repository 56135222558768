import React, { useState, useRef, useEffect, ReactNode } from "react";
import Switch from '@mui/material/Switch';
import Select, { components } from 'react-select';

import Modal from "./Modal";
import Text from "./base/Text";
import Divider from "./base/Divider";
import Document, { DocumentStatus, DocumentType } from "./Document";
import Button from "./base/Button";
import { analyzePolicy, comparePolicyCoverage, deleteDocument, generateEditedReport, generateProposal, getDocumentResults, getPresignedUrl, setDisplayName, updateCoverageLines, verifyCoverageEntry } from "../requests";
import { useCookies } from "react-cookie";
import AISearch from "./AISearch";
import LoadingSpinner from "./base/Spinner";
import { Viewer, Worker, ScrollMode } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Space from "./base/Space";
import './base/Button.css';
import './PDFModal.css';
import FileDrop from "./FileDrop";
import NewDocument from "./NewDocument";
import Container from "./Container";

import '@react-pdf-viewer/highlight/lib/styles/index.css';

import { highlightPlugin, HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import { Trigger } from '@react-pdf-viewer/highlight';
import ResultItem from "./ResultItem";
import Dropdown, { customStyles } from "./base/Dropdown";
import FeedbackFooter from "./FeedbackFooter";
import MarkdownPage from "./MarkdownPage";
import NewResultItem from "./NewResultItem";
import { DocumentsContainer } from "../Documents";
import { handleDownload, isAutoVerified } from "../utils";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import { Tooltip } from "react-tooltip";
import DocumentTypeDropdown from "./DocumentTypeDropdown";

const coverageLines = [
  "Cyber",
  "D&O",
  "Professional Liability",
  "Commercial Property",
  "General Liability",
  "Liquor Liability",
  "Workers Comp",
  "BOP",
  "Garage",
  "EPL",
  "Crime",
  "Pollution Liability",
  "Logging",
  "Builder's Risk",
  "Commercial Auto",
  "Inland Marine",
  "Umbrella",
  "Farm",
]

export interface ResultWithReference {
  // The generated unique identifier
  id: string;

  // The result content
  coverageName: string;

  coverageType: string;

  coverageLine: string;

  keys: any;

  // The list of highlight areas
  highlightArea: HighlightArea;

  // String of Page Ref information
  pageIndex: string;

  verified?: boolean;
}



const scheduleLineOptions = [
  'form_schedule', 
  'umbrella_schedule',
  'vehicle_list',
  'general_mod',
  'farm_schedule',
  'liquor_schedule',
  'inland_schedule',
  'address_list',
  'workers_comp_schedule',
  'garage_addresses',
  'driver_list',
  'contractor_property_list'
];

const PDFSidebarTab = ({ name, image, top, tab, setTab }: { name: string, top: string, tab: string, setTab: (s: string) => void, image: string }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  
  return (
    <div 
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        padding: "4px 15px",
        border: "1px solid black",
        borderTopRightRadius: "20px",
        borderTopLeftRadius: "20px",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        transform: `rotate(270deg)`,
        transformOrigin: "bottom right",
        transition: "all 0.3s ease",
        top: top,
        right: "0px",
        cursor: "pointer",
        zIndex: 11,
        backgroundColor: (name.toLowerCase() == tab) ? "#E7E7E7" : (isHovered ? "#ECECEC" : "#F6F6F6"),
      }}
      onClick={() => setTab(name.toLowerCase())}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={process.env.PUBLIC_URL + `/assets/${name.toLowerCase() == tab ? `${image}-blue` : `${image}-black`}.png`} style={{ cursor: "pointer", width: "20px", height: "20px", transition: "all 0.3s ease" }} />
      <Text size={16} align="center" color={name.toLowerCase() == tab ? "#4854F4" : "black"}>
        {name}
      </Text>
    </div>
  )
}

const PDFSidebar = ({ document, documents, setDocument, retrieveDocuments, lineOptions, selectedLine, setSelectedLine, selectedCoverageType, setSelectedCoverageType, selectedResultId, setSelectedResultId, filteredResultObjects, jumpToHighlightArea, setRefreshEntries, onScheduleClick, verifyMode, setVerifyMode, verifyPage, setVerifyPage }: {
  document: DocumentType,
  documents: DocumentType[],
  setDocument: (d: DocumentType | null) => void,
  retrieveDocuments: () => void,
  lineOptions: string[],
  selectedLine: string,
  setSelectedLine: (s: string) => void,
  selectedCoverageType: string,
  setSelectedCoverageType: (s: string) => void,
  selectedResultId: string,
  setSelectedResultId: (s: string) => void,
  filteredResultObjects: ResultWithReference[],
  jumpToHighlightArea: any,
  setRefreshEntries: (b: boolean) => void,
  onScheduleClick: (r: ResultWithReference) => void,
  verifyMode: boolean,
  setVerifyMode: (b: boolean) => void,
  verifyPage: string,
  setVerifyPage: (s: string) => void
}) => {

  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);

  const [tab, setTab] = useState<string>("analysis");
  const [periods, setPeriods] = useState<string>("");
  const [percentVerified, setPercentVerified] = useState<number>(0);
  
  const [loading, setLoading] = useState<boolean>(false);
  const [initialVerifyPages, setInitialVerifyPages] = useState<number>(0);
  const [currentResult, setCurrentResult] = useState<ResultWithReference | null>(null);
  const [fadeState, setFadeState] = useState("fade-out"); // Initial fade state

  useEffect(() => {
    if (document?.status == DocumentStatus.ANALYZING_POLICY) {
      const interval = setInterval(() => {
        setPeriods((prevPeriods) => (prevPeriods.length < 3 ? prevPeriods + '.' : ''));
      }, 500);
  
      return () => clearInterval(interval);
    }
  }, [document?.status]);

  useEffect(() => {
    setVerifyPage("initial");
  }, [tab, document])

  useEffect(() => {
    if (document?.percentVerified !== undefined) {
      setPercentVerified(document?.percentVerified)
    }

  }, [document?.percentVerified])

  useEffect(() => {
    if (verifyMode) {
      changeVerifyPage("initial");
      setInitialVerifyPages(filteredResultObjects.length);
    }

  }, [verifyMode])

  useEffect(() => {
    if (verifyMode) {
      if (filteredResultObjects.length > 0) {
        changeCurrentResult(filteredResultObjects[0]);
        setSelectedResultId(filteredResultObjects[0].id);
        jumpToHighlightArea(filteredResultObjects[0].highlightArea);

      } else {
        changeVerifyPage("congrats");
      }
    }

  }, [filteredResultObjects])

  const changeVerifyPage = async (s: string) => {
    setFadeState("fade-out");
    await new Promise(r => setTimeout(r, 300));
    setVerifyPage(s);
    await new Promise(r => setTimeout(r, 300));
    setFadeState("fade-in");
  }

  const changeCurrentResult = async (r: ResultWithReference) => {
    setFadeState("fade-out");
    await new Promise(r => setTimeout(r, 300));
    setCurrentResult(r);
    await new Promise(r => setTimeout(r, 300));
    setFadeState("fade-in");
  }

  return (
    <>
      <PDFSidebarTab name="Analysis" image="stars" top="80px" tab={tab} setTab={setTab} />
      <PDFSidebarTab name="Reports" image="report2" top="178px" tab={tab} setTab={setTab} />
      <PDFSidebarTab name="Comparisons" image="compare" top="274px" tab={tab} setTab={setTab} />
      <div style={{
        display: "flex",
        flexDirection: "column",
        height: "110%",
        overflowY: "scroll",
        width: "45%",
        overflowX: "visible"
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          height: "108%",
          overflowY: "scroll",
          position: "relative",
          overflowX: "visible"
        }}>
        
          { tab == "analysis" && (
            <>
              <div style={{ 
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <Text size={40} weight={600} align="center">
                  Policy Analysis
                </Text>
                { document?.badScan && (
                  <>
                    <img
                      data-tooltip-id="bad-scan-tooltip"
                      data-tooltip-content="Some pages in this document weren't scanned well, so our OCR model struggled a bit. You might notice some imperfect output."
                      style={{ width: "40px", height: "40px", cursor: "pointer" }} src={process.env.PUBLIC_URL + `/assets/bad-output.png`} />
                    <Tooltip id="bad-scan-tooltip" place="top" style={{ maxWidth: "250px", whiteSpace: "normal", fontFamily: "Assistant", zIndex: 1000 }} />
                  </>
                )}
              </div>
              
              <Space px={10} />
              { document?.status == "analyzed" && (
                <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgressWithLabel value={percentVerified} />
                  <Text size={16}>
                    verified
                  </Text>
                  <img
                    data-tooltip-id="verification-tooltip"
                    data-tooltip-content={
                      percentVerified == 100 
                        ? "All data points in this analysis have been manually verified by a human."
                        : "Percent of AI analysis verified by a human. Coverflow does this automatically, but click 'Verify More' to speed it up."
                    }
                    style={{ width: "20px", height: "20px", cursor: "pointer" }} src={process.env.PUBLIC_URL + `/assets/question.png`} />
                  <Tooltip id="verification-tooltip" place="top" style={{ maxWidth: "250px", whiteSpace: "normal", fontFamily: "Assistant" }} />
                  { percentVerified < 100 && !verifyMode && (
                    <>
                      <div style={{ marginLeft: "30px" }} />
                      <Button color="white" border="2px solid black" padding="6px" onClick={() => setVerifyMode(true)}>
                        <div style={{ display: "flex", flexDirection: "row", gap: "6px", alignItems: "center" }}>
                          <img src={process.env.PUBLIC_URL + "/assets/verified.png"} style={{ width: "20px", height: "20px" }} />
                          <Text size={16} weight={600}>
                            Finish Verifying
                          </Text>
                        </div>
                      </Button>
                    </>
                  )}
                </div>
              )}
              { document?.status === "uploaded" && (
                <>
                  <Space px={150} />
                  <Text size={40} color="gray" align="center">
                    Analyze policy to see results here
                  </Text>
                </>
              )}
              { document?.status === "analyzing_policy" && (
                <>
                  <Space px={100} />
                  <Text size={40} color="gray" align="center">
                    Analyzing{periods}
                  </Text>
                </>
              )}
              { document?.status === "analyzed" && (
                <>
                  <Space px={15} />
                  { !verifyMode && (
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "95%",
                      gap: "30px"
                    }}>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "220px"
                      }}>
                        <Text size={16} weight={600}>
                          Coverage Line
                        </Text>
                        <Select
                          value={
                            lineOptions.map(d => ({ value: d, label: d })).find(c => c.value == selectedLine) 
                          }
                          onChange={(option) => {
                            setSelectedLine(option?.value ?? "");
                          }}
                          options={lineOptions.map(d => ({ value: d, label: d }))}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      </div>
                      <Dropdown options={[
                        {
                          label: "Key Information",
                          value: "generic"
                        },
                        {
                          label: "Coverage",
                          value: "coverage"
                        },
                        {
                          label: "Endorsement",
                          value: "endorsement",
                        },
                        {
                          label: "Exclusions",
                          value: "exclusion",
                        },
                        {
                          label: "Schedule",
                          value: "schedule"
                        }]} option={selectedCoverageType} setOption={setSelectedCoverageType} label="Result Type" width="220px" />
                    </div>
                  )}
                  { !verifyMode && (
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "95%",
                      gap: "10px"
                    }}>
                      <Space px={20} />
                      {filteredResultObjects.map(result => (
                        <ResultItem
                          result={result}
                          onClick={() => {
                            jumpToHighlightArea(result.highlightArea);
                            setSelectedResultId(result.id);
                          }}
                          selected={result.id == selectedResultId}
                          onDoubleClick={() => setSelectedResultId("")}
                          setRefresh={setRefreshEntries}
                          onScheduleClick={onScheduleClick}
                          retrieveDocuments={retrieveDocuments}
                          setPercentVerified={setPercentVerified}
                          showVerify
                        />
                      ))}
                      { selectedCoverageType != "schedule" && selectedCoverageType != "exclusion" && !verifyMode && (
                        <NewResultItem
                          coverageLine={selectedLine}
                          coverageType={selectedCoverageType}
                          documentInstanceId={document?.instanceId ?? ""}
                          onClick={() => setSelectedResultId("new_result")}
                          selected={"new_result" == selectedResultId}
                          onDoubleClick={() => setSelectedResultId("")}
                          setRefresh={setRefreshEntries}
                        />
                      )}
                      <Space px={50} />
                    </div>
                  )}
                  { verifyMode && (
                    <div 
                      className={`fade-container ${fadeState}`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      { verifyPage == "initial" && (
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "80%"
                        }}>
                          <Space px={70} />
                          <Text size={35} align="center">
                            Help us ensure everything's accurate.
                          </Text>
                          <Button color="white" border="2px solid black" padding="6px" width="200px" onClick={() => changeVerifyPage("verify")}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "6px", alignItems: "center" }}>
                              <img src={process.env.PUBLIC_URL + "/assets/verified.png"} style={{ width: "30px", height: "30px" }} />
                                <Text size={30} align="center">
                                  Let's Go
                                </Text>
                            </div>
                          </Button>
                        </div>
                      )}
                      { verifyPage == "verify" && currentResult && (
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "90%"
                        }}>
                          <Space px={5} />
                          <Text size={40} align="center">
                            Is this accurate?
                          </Text>
                          <ResultItem
                            result={currentResult}
                            onClick={() => {
                              if (currentResult) {
                                jumpToHighlightArea(currentResult.highlightArea);
                                setSelectedResultId(currentResult.id);
                              }
                            }}
                            selected={currentResult?.id == selectedResultId}
                            onDoubleClick={() => setSelectedResultId("")}
                            setRefresh={setRefreshEntries}
                            onScheduleClick={onScheduleClick}
                            retrieveDocuments={retrieveDocuments}
                            setPercentVerified={setPercentVerified}
                          />
                          <Space px={100} />
                        </div>
                      )}
                      { verifyPage == "congrats" && (
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "80%"
                        }}>
                          <Space px={70} />
                          <Text size={30} align="center">
                            Congrats! You've verified that this analysis is 100% accurate.
                          </Text>
                          <Button color="white" border="2px solid black" padding="6px" width="200px" onClick={() => {
                            setVerifyMode(false);
                            setVerifyPage("initial");
                          }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "6px", alignItems: "center" }}>
                              <img src={process.env.PUBLIC_URL + "/assets/verified.png"} style={{ width: "30px", height: "30px" }} />
                                <Text size={30} align="center">
                                  Complete
                                </Text>
                            </div>
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                  { !verifyMode && <FeedbackFooter documentInstanceId={document.instanceId ?? ""} /> }
                  { verifyMode && verifyPage == "verify" && (
                    <div style={{
                      position: "sticky",
                      marginTop: "auto",
                      bottom: "0px",
                      width: "calc(100% - 20px)",
                      height: "50px",
                      maxHeight: "80px",
                      padding: "10px",
                      backgroundColor: "white",
                      borderTop: "1px solid black",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}>
                      <Button color="red" padding="10px" onClick={() => {
                        setVerifyMode(false);
                        setVerifyPage("initial");
                      }}>
                        <Text size={20} color="white" weight={600}>
                          Done Verifying
                        </Text>
                      </Button>
                      <Space px={5} />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          { tab == "reports" && (
            <>
              <Text size={40} weight={600} align="center">
                Associated Reports
              </Text>
              <Space px={20} />
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", gap: "10px" }}>
                { documents.filter(d => d.instanceIds && d.instanceIds.length == 1 && d.instanceIds?.includes(document?.instanceId ?? "")).map(d => (
                  <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments} onClick={() => {
                    setDocument(d);
                  }} />
                ))}
              </div>
              <Space px={50} />
            </>
          )}
          { tab == "comparisons" && (
            <>
              <Text size={40} weight={600} align="center">
                Associated Comparisons
              </Text>
              <Space px={20} />
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", gap: "10px" }}>
                { documents.filter(d => d.instanceIds && d.instanceIds.length > 1 && d.instanceIds?.includes(document?.instanceId ?? "")).map(d => (
                  <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments} onClick={() => {
                    setDocument(d);
                  }} />
                ))}
              </div>
              <Space px={50} />
            </>
          )}
        </div>
      </div>
    </>
  )
};

const PDFModal = ({ setModalOpen, modalOpen, document, documents, setDocument, retrieveDocuments, setSuccessMessage, setErrorMessage, secondDocument, setSecondDocument, setComparisonModalOpen, agencyId, clientId, setClientId, documentType, setDocumentType, secondDocumentType, setSecondDocumentType }: { 
  setModalOpen: (b: boolean) => void,
  modalOpen: boolean,
  document: DocumentType | null,
  documents: DocumentType[],
  retrieveDocuments: () => void,
  setDocument: (d: DocumentType | null) => void,
  secondDocument: DocumentType | null,
  setSecondDocument: (d: DocumentType | null) => void,
  documentType: string,
  setDocumentType: (s: string) => void,
  secondDocumentType: string,
  setSecondDocumentType: (s: string) => void,
  setSuccessMessage: (s: string) => void,
  setErrorMessage: (s: string) => void,
  setComparisonModalOpen: (b: boolean) => void,
  agencyId: string,
  clientId: string,
  setClientId: (s: string) => void,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(null);
  const [page, setPage] = useState<string>("normal");
  const [policyInfo, setPolicyInfo] = useState<string>("");
  const [generationStarted, setGenerationStarted] = useState<string | null>(null);
  const [presignedUrl, setPresignedUrl] = useState<string>("");
  const [deleteButtonClicked, setDeleteButtonClicked] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [selectedLines, setSelectedLines] = useState<string[]>([]);
  const [objective, setObjective] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [editingName, setEditingName] = useState<boolean>(false);
  const [editedName, setEditedName] = useState<string>("");
  const [localDisplayName, setLocalDisplayName] = useState<string>("");
  const [endorsements, setEndorsements] = useState<DocumentType[]>([]);
  const [schedules, setSchedules] = useState<DocumentType[]>([]);
  const [markdownReport, setMarkdownReport] = useState<boolean>(true);
  const [additionalDocument, setAdditionalDocument] = useState<DocumentType | null>(null);
  const [additionalDocumentUrl, setAdditionalDocumentUrl] = useState<string>("");
  const [resultObjects, setResultObjects] = useState<ResultWithReference[]>([]);
  const [filteredResultObjects, setFilteredResultObjects] = useState<ResultWithReference[]>([]);
  const [selectedResultId, setSelectedResultId] = useState<string>("");
  const [selectedResult, setSelectedResult] = useState<ResultWithReference[]>([]);
  const [lineOptions, setLineOptions] = useState<string[]>([]);
  const [selectedLine, setSelectedLine] = useState<string>("");
  const [selectedCoverageType, setSelectedCoverageType] = useState<string>("generic");
  const [refreshEntries, setRefreshEntries] = useState<boolean>(false);
  const [proposalDocuments, setProposalDocuments] = useState<(DocumentType | null)[]>([]);
  const [scheduleResult, setScheduleResult] = useState<ResultWithReference | null>(null);
  const [verifyMode, setVerifyMode] = useState<boolean>(false);
  const [verifyPage, setVerifyPage] = useState<string>("initial");

  const onScheduleClick = (r: ResultWithReference) => {
    setScheduleResult(r);
    setPage("markdown");
  }

  const onModalCloseInternal = () => {
    setDeleteButtonClicked(false);
    setSelectedLine("");
    setLineOptions([]);
    setPage("normal");
    setPresignedUrl("");
    setGenerationStarted(null);
    setSelectedResultId("");
    setSelectedResult([]);
    setVerifyPage("initial");
    setVerifyMode(false);
  }

  const onGenerateEdited = async () => {
    await generateEditedReport(cookies['user-id'], document?.instanceId ?? "", markdownReport);
    
    retrieveDocuments();
    setModalOpen(false);
    onModalCloseInternal();
  }

  const onGenerateProposal = async () => {
    await generateProposal(cookies['user-id'], [document?.instanceId ?? "", ...proposalDocuments.map(d => d?.instanceId ?? "")]);
    
    retrieveDocuments();
    setModalOpen(false);
    onModalCloseInternal();
  }

  const onGenerate = async (reportType: string) => {
    setGenerationStarted(reportType);
    if (reportType != "comparison") {
      const policyResp = await analyzePolicy(cookies['user-id'], policyInfo, document?.instanceId ?? "", reportType ?? "", markdownReport);
      if (policyResp.body["bad_policy"]) {
        setErrorMessage("We are unable to generate reports for this coverage line. We apologize for this inconvenience.");
        setGenerationStarted(null);
      }
    } else {
      await comparePolicyCoverage(cookies['user-id'], [document?.instanceId ?? "", secondDocument?.instanceId ?? ""], markdownReport, [documentType, secondDocumentType]);
    }
    
    retrieveDocuments();
    setModalOpen(false);
    onModalCloseInternal();
  }

  const onGenerateThreeWay = async () => {
    await comparePolicyCoverage(cookies['user-id'], [document?.instanceId ?? "", ...proposalDocuments.map(d => d?.instanceId ?? "")], markdownReport);
    
    retrieveDocuments();
    setModalOpen(false);
    onModalCloseInternal();
  }

  const deleteDoc = async () => {
    setDeleting(true);
    const resp = await deleteDocument(cookies['user-id'], document?.instanceId ?? "", document?.category != "policy");
    if (resp.status == 200) {
      setSuccessMessage("Document deleted successfully.");
      retrieveDocuments();
      setModalOpen(false);
      onModalCloseInternal();
    } else {
      setErrorMessage("Unable to delete the document at this time. Please try again in a minute.")
    }

    setDeleting(false);
  }

  const deleteAdditionalDoc = async () => {
    setDeleting(true);
    const resp = await deleteDocument(cookies['user-id'], additionalDocument?.instanceId ?? "", false);
    if (resp.status == 200) {
      setSuccessMessage("Document deleted successfully.");
      retrieveDocuments();
      setDeleteButtonClicked(false);
      setPage("additionalDocuments");
    } else {
      setErrorMessage("Unable to delete the document at this time. Please try again in a minute.")
    }

    setDeleting(false);
  }

  const saveDisplayName = async () => {
    const resp = await setDisplayName(cookies['user-id'], document?.instanceId ?? "", editedName, document?.category != "policy");
    if (resp.status == 200) {
      retrieveDocuments();
      setLocalDisplayName(editedName);
      setEditingName(false);
    } else {
      setErrorMessage("Unable to edit document name at this time. Please try again in a minute.")
    }
    
  }

  const onViewAdditionalDocument = async (d: DocumentType) => {
    const resp = await getPresignedUrl(cookies['user-id'], d?.link ?? "", d?.instanceId ?? "", false, false);
    setAdditionalDocument(d);
    setAdditionalDocumentUrl(resp.body["url"]);
    setPage("viewAdditionalDocument");
  }

  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {selectedResult.map((result) => (
        <React.Fragment key={result.id}>
          {/* Check if the highlightArea's pageIndex matches the current pageIndex */}
          {result.highlightArea.pageIndex === props.pageIndex && (
            <div
              style={Object.assign(
                {},
                {
                  background: 'yellow',
                  opacity: 0.4,
                },
                // Apply position and dimensions using getCssProperties function
                props.getCssProperties(result.highlightArea, props.rotation)
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({renderHighlights, trigger: Trigger.None});
  const { jumpToHighlightArea } = highlightPluginInstance;

  useEffect(() => {
    const findResultById = (results: ResultWithReference[], id: string): ResultWithReference | undefined => {
      return results.find(result => result.id === id);
    };

    async function updateHighlights() {
      const foundResult = findResultById(resultObjects, selectedResultId);
      if (foundResult === undefined) {
        console.log("NOTHING CLICKED, RESULT NOT FOUND")
      } else {
        console.log("FOUND RESULT");
        console.log(foundResult);
        setSelectedResult([foundResult]);
      }
    }

    updateHighlights();

    return () => {};

  }, [resultObjects, selectedResultId])


  useEffect(() => {
    let ends: DocumentType[] = [];
    let schds: DocumentType[] = [];
    documents.filter(d => d.associatedDocumentId == document?.instanceId).forEach(d => {
      if (d.category == "endorsement") ends.push(d);
      else if (d.category == "schedule") schds.push(d);
    })

    setEndorsements(ends);
    setSchedules(schds);
  }, [document, documents])

  useEffect(() => {
    setLocalDisplayName(document?.name ?? "");
  }, [document, document?.name])

  useEffect(() => {
    setEditedName(document?.name ?? "");
  }, [document, document?.name])

  useEffect(() => {
    const autoVerified = isAutoVerified(document?.createdAt);
    const translateResultsToReactInterface = (results: any[]): ResultWithReference[] => {
      return results.map((result) => {
        return {
          id: result.coverage_id,
          coverageName: result.coverage_name,
          coverageType: result.coverage_type,
          coverageLine: (
            scheduleLineOptions.includes(result.coverage_line)
              ? ""
              : (result.coverage_line ?? '')
          ),
          keys: result.keys,
          highlightArea: {
            height: result.highlight_area.height,
            width: result.highlight_area.width,
            left: result.highlight_area.left,
            top: result.highlight_area.top,
            pageIndex: result.highlight_area.page_index
          },
          pageIndex: result.page_index,
          verified: autoVerified ? true : result.verified
        };
      });
    };
    
    async function retrieveDocumentResults() {
      if (modalOpen) {
        try {
          const resp = await getDocumentResults(cookies['user-id'], document?.instanceId ?? "");
          const translatedResults = translateResultsToReactInterface(resp.body["results"]);
          setResultObjects(translatedResults);
          let newLines: string[] = [];
          for (let i = 0; i < translatedResults.length; i++) {
            const newLine = translatedResults[i].coverageLine;
            if (newLine != "" && !newLines.includes(newLine)) {
              newLines.push(newLine);
            }
          }
          setLineOptions(newLines);
          console.log("RESPONSE RESULTS");
          console.log(translatedResults);
        } catch (error) {
          console.error("Failed to retrieve presigned URL:", error);
        }
      }
    }
  
    retrieveDocumentResults(); // Initial call

    if (refreshEntries) {
      setRefreshEntries(false);
    }
  
    return () => {};
  
  }, [document, document?.instanceId, document?.category, modalOpen, refreshEntries]);

  useEffect(() => {
    if (!verifyMode) {
      setFilteredResultObjects(
        resultObjects.filter(
          r => (
            (r.coverageLine == selectedLine && r.coverageType == selectedCoverageType) || 
            (r.coverageType == "generic" && selectedCoverageType == "generic") || 
            (r.coverageType == "schedule" && selectedCoverageType == "schedule")
          )
        )
      );
    } else {
      setFilteredResultObjects(resultObjects.filter(r => !r.verified && r.coverageType != "endorsement" && r.coverageType != "schedule"));
    }
  
  }, [resultObjects, selectedLine, selectedCoverageType, verifyMode])

  useEffect(() => {
    if (verifyMode && filteredResultObjects.length > 0) {
      setSelectedResultId(filteredResultObjects[0].id);
    }
  }, [filteredResultObjects])


  useEffect(() => {
    let intervalId;
  
    async function retrievePresignedUrl() {
      if (modalOpen) {
        try {
          const resp = await getPresignedUrl(cookies['user-id'], document?.link ?? "", document?.instanceId ?? "", (document?.category == "comparison" || document?.category == "analysis"), document?.category == "proposal");
          setPresignedUrl(resp.body["url"]);
        } catch (error) {
          console.error("Failed to retrieve presigned URL:", error);
        }
      }
    }
  
    retrievePresignedUrl(); // Initial call
  
    if (modalOpen) {
      intervalId = setInterval(retrievePresignedUrl, 59 * 60 * 1000); // Set interval for 59 minutes
    }
  
    return () => {
      clearInterval(intervalId); // Clear interval on component unmount or dependencies change
    };
  
  }, [document, document?.instanceId, document?.category, modalOpen]);

  useEffect(() => {
    setSelectedLines(document?.lines ?? []);
    if (lineOptions.length > 0) {
      setSelectedLine(lineOptions[0]);
    }
  }, [document, document?.lines, lineOptions, modalOpen])

  const onLineClick = (l: string) => {
    if (!!selectedLines.find(l2 => l2 == l)) {
      setSelectedLines(selectedLines.filter(l2 => l2 != l));
    } else {
      setSelectedLines(prev => [...prev, l]);
    }
  }

  const onConfirmLines1 = async () => {
    setLoading(true);
    await updateCoverageLines(cookies['user-id'], document?.instanceId ?? "", selectedLines);
    if (objective == "report") {
      await onGenerate("internal");
    } else {
      setSelectedLines(selectedDocument?.lines ?? []);
      setPage("confirmLines2");
    }
    setLoading(false);
  }

  const onConfirmLines2 = async () => {
    setLoading(true);
    await updateCoverageLines(cookies['user-id'], secondDocument?.instanceId ?? "", selectedLines);
    await onGenerate("comparison");
    setLoading(false);
  }

  const onDocumentClick = (d2: DocumentType) => {
    setSelectedDocument(d2);
  }
  
  const onCompareSelect = async () => {
    setSecondDocument(selectedDocument);
    setPage("compare");
  }

  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen} id="pdfModal" onModalClose={() => {
      onModalCloseInternal();
    }} extraStyles={{
      padding: "40px",
      border: "1px solid #B9B9B9",
      boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
      width: "90%",
      height: "80%",
      backgroundColor: "#ffffff",
      overflowY: "scroll",
      position: "relative"
    }}>
      { page == "normal" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: 'center',
              justifyContent: "flex-start",
              width: "90%",
              gap: "20px"
            }}>
              { !editingName && (
                <div style={{ maxWidth: "50%" }}>
                  <Text size={35} weight={600}>
                    {localDisplayName}
                  </Text>
                </div>
              )}
              { editingName && (
                <input
                  type="text"
                  value={editedName}
                  onChange={(e) => setEditedName(e.currentTarget.value)}
                  style={{
                    maxWidth: "90%",
                    width: `${editedName.length * 16.5}px`,
                    borderRadius: "8px",
                    fontSize: "35px",
                    fontWeight: 600,
                    padding: "0px",
                    border: "none",
                    outline: "none",
                    fontFamily: "Assistant, sans-serif",
                  }}
                />
              )}
              { !editingName && (
                <img src={process.env.PUBLIC_URL + "/assets/edit.png"} style={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={() => setEditingName(true)} />
              )}
              { editingName && (
                <>
                  <img src={process.env.PUBLIC_URL + "/assets/cancel.png"} style={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={() => {
                    setEditedName(document?.name ?? "");
                    setEditingName(false);
                  }} />
                  <img src={process.env.PUBLIC_URL + "/assets/green-check.png"} style={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={saveDisplayName} />
                </>
              )}
              { document?.category == "policy" && (
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px"
                }}>
                  {document?.status == "uploaded" && (
                    <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={() => {
                      setObjective("report");
                      setPage("confirmLines1");
                    }}> 
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px"
                      }}>
                        <img src={process.env.PUBLIC_URL + "/assets/stars-blue.png"} style={{ width: "20px", height: "20px" }} />
                        <Text size={16}>
                          Analyze Policy
                        </Text>
                      </div>
                    </Button>
                  )}
                  {document?.status == "analyzed" && (
                    <>
                      { generationStarted != "internal" && (
                        <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={onGenerateEdited}> 
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px"
                          }}>
                            <img src={process.env.PUBLIC_URL + "/assets/report2-blue.png"} style={{ width: "20px", height: "20px" }} />
                            <Text size={16}>
                              Report
                            </Text>
                          </div>
                        </Button>
                      )}
                      { ( agencyId == process.env.REACT_APP_TWIN_CITIES_AGENCY_ID || agencyId == process.env.REACT_APP_ISU_AGENCY_ID || agencyId == process.env.REACT_APP_PORTAL_AGENCY_ID ) && (
                        <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={() => setPage("compare_proposal")}> 
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px"
                          }}>
                            <img src={process.env.PUBLIC_URL + "/assets/proposal-blue.png"} style={{ width: "20px", height: "20px" }} />
                            <Text size={16}>
                              Proposal
                            </Text>
                          </div>
                        </Button>
                      )}
                      <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={() => {
                        setDocumentType("policy");
                        setSecondDocumentType("policy");
                        setPage("compare");
                      }}> 
                        <div style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: 'center',
                        }}>
                          <img src={process.env.PUBLIC_URL + "/assets/compare-blue.png"} style={{ width: "25px", height: "25px" }} />
                          <Text size={16}>
                            { agencyId == process.env.REACT_APP_LONG_LAKE_AGENCY_ID && "2-Way" } Compare
                          </Text>
                        </div>
                      </Button>
                      { ( agencyId == process.env.REACT_APP_LONG_LAKE_AGENCY_ID ) && (
                        <Button padding="10px" maxHeight="20px" color="#ffffff" border="1px solid black" onClick={() => setPage("compare_3_way")}> 
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: 'center',
                          }}>
                            <img src={process.env.PUBLIC_URL + "/assets/compare-blue.png"} style={{ width: "25px", height: "25px" }} />
                            <Text size={16}>
                              3-Way Compare
                            </Text>
                          </div>
                        </Button>
                      )}
                      { (agencyId != process.env.REACT_APP_TWIN_CITIES_AGENCY_ID && agencyId != process.env.REACT_APP_LONG_LAKE_AGENCY_ID) && (
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <Text size={16}>
                            Text Report?
                          </Text>
                          <Switch checked={markdownReport} onChange={() => setMarkdownReport(!markdownReport)} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              { document?.category != "policy" && (
                <img src={process.env.PUBLIC_URL + "/assets/download.png"} style={{ height: "30px", cursor: "pointer" }} onClick={() => handleDownload(document, cookies['user-id'], window)} />
              )}
            </div>
            <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px"
            }}>
              { document?.category == "policy" && (
                <Button padding="10px" maxHeight="20px" color="white" border="1px solid black" onClick={() => setPage("additionalDocuments")}> 
                  <Text size={16}>
                    Upload
                  </Text>
                </Button>
              )}
              { !deleteButtonClicked && (
                <Button padding="10px" maxHeight="20px" color="white" border="1px solid red" onClick={() => setDeleteButtonClicked(true)}> 
                  <Text size={16} color="red">
                    Delete
                  </Text>
                </Button>
              )}
              { deleteButtonClicked && (
                <>
                  <Button padding="10px" maxHeight="20px" color="white" border="1px solid black" onClick={() => setDeleteButtonClicked(false)}> 
                    <Text size={16} color="black">
                      Cancel
                    </Text>
                  </Button>
                  { !deleting && (
                    <Button padding="10px" maxHeight="20px" color="red" onClick={deleteDoc}> 
                      <Text size={16} color="white">
                        Delete Document
                      </Text>
                    </Button>
                  )}
                  { deleting && (
                    <LoadingSpinner />
                  )}
                </>
              )}
            </div>
          </div>
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            gap: "20px",
            height: "80%",
          }}>
            {/* <iframe
              src={presignedUrl}
              width="100%"
              height="110%"
            /> */}
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">  
                  {presignedUrl && (
                    <div style={{
                      border: '1px solid rgba(0, 0, 0, 0.3)',
                      width: "70%",
                      height: "100%",
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                    }}>
                      <Viewer fileUrl={presignedUrl} plugins={[highlightPluginInstance]} />
                              </div>
                              )}         
                </Worker>
            { (document?.instanceIds === undefined && document?.category == "policy") && (
              <PDFSidebar   
                document={document}
                documents={documents}
                setDocument={setDocument}
                retrieveDocuments={retrieveDocuments}
                lineOptions={lineOptions}
                selectedLine={selectedLine}
                setSelectedLine={setSelectedLine}
                selectedCoverageType={selectedCoverageType}
                setSelectedCoverageType={setSelectedCoverageType}
                selectedResultId={selectedResultId}
                setSelectedResultId={setSelectedResultId}
                filteredResultObjects={filteredResultObjects}
                jumpToHighlightArea={jumpToHighlightArea}
                setRefreshEntries={setRefreshEntries}
                onScheduleClick={onScheduleClick}
                verifyMode={verifyMode}
                setVerifyMode={setVerifyMode}
                verifyPage={verifyPage}
                setVerifyPage={setVerifyPage}
              />
            )}
            { document?.status == "failed" && (
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                width: "50%"
              }}>
                <Text size={40} align="center" color="gray">
                  Analysis Failed
                </Text>
                <Space px={20} />
                <Text size={30} align="center">
                  What does this mean?
                </Text>
                <Text size={20} align="center">
                  Something about the policy wasn't what our system expected. We've been alerted, and we're looking into it ASAP. Don't worry — you weren't charged a credit for this one.
                </Text>
                <Space px={20} />
                <Text size={30} align="center">
                  What should I do?
                </Text>
                <Text size={20} align="center">
                  You can try re-analyzing the policy above, although that might not work. You can also reach out to us anytime at 281-224-0743, or matthew@coverflow.tech, to check on the status of our investigation.
                </Text>
              </div>

            )}
          </div>
          
        </>
      )}
      { page == "compare" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px"
          }}>
            <Text size={40} weight={600} align="center">
              Select a document to compare with <div style={{ color: "#4854F4", display: "inline", fontFamily: "Assistant", fontSize: 40 }}>{localDisplayName}</div>.
            </Text>        
          </div>
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "60px",
            alignItems: "center",
            justifyContent: "center",
            height: "70%"
          }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center" }}>
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                width: "280px",
                height: "280px",
              }}>
                <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
                <Text size={30} weight={600} align="center">
                  {document?.name && document?.name.length > 17 ? document?.name.slice(0, 17) + "..." : document?.name}
                </Text>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center" }}>
                <Text size={25}>
                  Document Type: 
                </Text>
                <DocumentTypeDropdown documentType={documentType} setDocumentType={setDocumentType} />
              </div>
            </div>
            <Text size={14} color="gray">
              and
            </Text>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", alignItems: "center" }}>
              { !secondDocument && (
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  width: "280px",
                  height: "280px",
                  border: "1px solid black",
                  cursor: "pointer"
                }} onClick={() => setPage("selecting")}>
                  <img style={{ width: "75px", height: "75px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/plus.png"} />
                </div>
              )}
              { secondDocument && (
                <div style={{
                  position: "relative"
                }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "280px",
                    height: "280px",
                  }}>
                    <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
                    <Text size={30} weight={600} align="center">
                      {secondDocument?.name && secondDocument?.name.length > 17 ? secondDocument?.name.slice(0, 17) + "..." : secondDocument?.name}
                    </Text>
                  </div>
                  <img style={{ width: "50px", height: "50px", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={ process.env.PUBLIC_URL + "/assets/cancel.png"} onClick={() => setSecondDocument(null)} />
                </div>
              )}
              <div style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center" }}>
                <Text size={25}>
                  Document Type: 
                </Text>
                <DocumentTypeDropdown documentType={secondDocumentType} setDocumentType={setSecondDocumentType} />
              </div>
            </div>
          </div>
          <div style={{ position: "absolute", bottom: "30px", right: "30px", display: "flex", flexDirection: "row", gap: "20px" }}>
            { !generationStarted && (
              <>
                <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("normal")}>
                  <Text size={20} weight={600} color="#000000">
                    Cancel
                  </Text>
                </Button>
                <Button width="200px" height="55px" greyed={!secondDocument} onClick={() => {
                  setComparisonModalOpen(true);
                }}>
                  <Text size={20} weight={600} color="white">
                    Next
                  </Text>
                </Button>
              </>
            )}
            { generationStarted && (
              <LoadingSpinner />
            )}
          </div>
        </>
      )}
      { page == "compare_proposal" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px"
          }}>
            <Text size={40} weight={600} align="center">
              Select any policies to add to the proposal.
            </Text>        
          </div>
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "60px",
            alignItems: "center",
            justifyContent: "center",
            height: "70%"
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              width: "280px",
              height: "280px",
            }}>
              <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
              <Text size={30} weight={600} align="center">
                {document?.name && document?.name.length > 17 ? document?.name.slice(0, 17) + "..." : document?.name}
              </Text>
            </div>
            <Text size={14} color="gray">
              and
            </Text>
            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
              //alignItems: "center",
              //justifyContent: "center",
              width: "700px",
              height: "400px",
            }}>
              { proposalDocuments.map(d => (
                <div style={{
                  position: "relative"
                }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "150px",
                    height: "150px",
                  }}>
                    <img style={{ width: "60px", height: "60px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
                    <Text size={18} weight={600} align="center">
                      {d?.name && d?.name.length > 17 ? d.name.slice(0, 17) + "..." : d?.name}
                    </Text>
                  </div>
                  <img style={{ width: "30px", height: "30px", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={ process.env.PUBLIC_URL + "/assets/cancel.png"} onClick={() => {
                    setProposalDocuments(prev => prev.filter(d2 => d?.instanceId != d2?.instanceId))
                  }} />
                </div>
              ))}
              { proposalDocuments.length < 10 && (
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  width: "150px",
                  height: "150px",
                  border: "1px solid black",
                  cursor: "pointer"
                }} onClick={() => setPage("selecting_proposal")}>
                  <img style={{ width: "50px", height: "50px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/plus.png"} />
                </div>
              )}
            </div>
          </div>
          <div style={{ position: "absolute", bottom: "30px", right: "30px", display: "flex", flexDirection: "row", gap: "20px" }}>
            { !generationStarted && (
              <>
                <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("normal")}>
                  <Text size={20} weight={600} color="#000000">
                    Cancel
                  </Text>
                </Button>
                <Button width="200px" height="55px" onClick={onGenerateProposal}>
                  <Text size={20} weight={600} color="white">
                    Generate Proposal
                  </Text>
                </Button>
              </>
            )}
            { generationStarted && (
              <LoadingSpinner />
            )}
          </div>
        </>
      )}
      { page == "compare_3_way" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px"
          }}>
            <Text size={40} weight={600} align="center">
              Select two policies to compare with <div style={{ color: "#4854F4", display: "inline", fontFamily: "Assistant", fontSize: 40 }}>{localDisplayName}</div>.
            </Text>        
          </div>
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "60px",
            alignItems: "center",
            justifyContent: "center",
            height: "70%"
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              width: "280px",
              height: "280px",
            }}>
              <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
              <Text size={30} weight={600} align="center">
                {document?.name && document?.name.length > 17 ? document?.name.slice(0, 17) + "..." : document?.name}
              </Text>
            </div>
            <Text size={14} color="gray">
              and
            </Text>
            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
              //alignItems: "center",
              //justifyContent: "center",
              width: "600px",
              height: "280px",
            }}>
              { proposalDocuments.map(d => (
                <div style={{
                  position: "relative"
                }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "280px",
                    height: "280px",
                  }}>
                    <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
                    <Text size={30} weight={600} align="center">
                      {d?.name && d?.name.length > 17 ? d.name.slice(0, 17) + "..." : d?.name}
                    </Text>
                  </div>
                  <img style={{ width: "30px", height: "30px", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={ process.env.PUBLIC_URL + "/assets/cancel.png"} onClick={() => {
                    setProposalDocuments(prev => prev.filter(d2 => d?.instanceId != d2?.instanceId))
                  }} />
                </div>
              ))}
              { proposalDocuments.length < 2 && (
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  width: "280px",
                  height: "280px",
                  border: "1px solid black",
                  cursor: "pointer"
                }} onClick={() => setPage("selecting_3_way")}>
                  <img style={{ width: "50px", height: "50px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/plus.png"} />
                </div>
              )}
            </div>
          </div>
          <div style={{ position: "absolute", bottom: "30px", right: "30px", display: "flex", flexDirection: "row", gap: "20px" }}>
            { !generationStarted && (
              <>
                <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("normal")}>
                  <Text size={20} weight={600} color="#000000">
                    Cancel
                  </Text>
                </Button>
                <Button greyed={proposalDocuments.length < 2} width="200px" height="55px" onClick={onGenerateThreeWay}>
                  <Text size={20} weight={600} color="white">
                    Compare
                  </Text>
                </Button>
              </>
            )}
            { generationStarted && (
              <LoadingSpinner />
            )}
          </div>
        </>
      )}
      { page == "selecting" && (
        <>
          <Text size={35} weight={600}>
            Select a document to compare
          </Text>
          <Divider />
          <DocumentsContainer clientId={clientId} category="policy" documents={documents} retrieveDocuments={retrieveDocuments} onDocumentClick={onDocumentClick} setClientId={setClientId} setErrorMessage={setErrorMessage} selecting selectedDocument={selectedDocument ?? undefined} includePercent />            
          <div style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "flex-end" }}>
            <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("compare")}>
              <Text size={20} weight={600} color="#000000">
                Cancel
              </Text>
            </Button>
            <Button greyed={!selectedDocument} onClick={onCompareSelect}>
              <Text size={16} weight={600} color="white">
                Select Document
              </Text>
            </Button>
          </div>
        </>
      )}
      { page == "selecting_proposal" && (
        <>
          <Text size={35} weight={600}>
            Select a policy to add to the proposal
          </Text>
          <Divider />
          <DocumentsContainer clientId={clientId} category="policy" documents={documents} retrieveDocuments={retrieveDocuments} onDocumentClick={onDocumentClick} setClientId={setClientId} setErrorMessage={setErrorMessage} selecting selectedDocument={selectedDocument ?? undefined} includePercent />            
          <div style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "flex-end" }}>
            <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("compare_proposal")}>
              <Text size={20} weight={600} color="#000000">
                Cancel
              </Text>
            </Button>
            <Button greyed={!selectedDocument} onClick={() => {
              setProposalDocuments(prev => [...prev, selectedDocument]);
              setPage("compare_proposal")
            }}>
              <Text size={16} weight={600} color="white">
                Select Document
              </Text>
            </Button>
          </div>
        </>
      )}
      { page == "selecting_3_way" && (
        <>
          <Text size={35} weight={600}>
            Select a policy to add to the comparison
          </Text>
          <Divider />
          <DocumentsContainer clientId={clientId} category="policy" documents={documents} retrieveDocuments={retrieveDocuments} onDocumentClick={onDocumentClick} setClientId={setClientId} setErrorMessage={setErrorMessage} selecting selectedDocument={selectedDocument ?? undefined} includePercent />            
          <div style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "flex-end" }}>
            <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("compare_3_way")}>
              <Text size={20} weight={600} color="#000000">
                Cancel
              </Text>
            </Button>
            <Button greyed={!selectedDocument} onClick={() => {
              setProposalDocuments(prev => [...prev, selectedDocument]);
              setPage("compare_3_way")
            }}>
              <Text size={16} weight={600} color="white">
                Select Document
              </Text>
            </Button>
          </div>
        </>
      )}
      { page == "confirmLines1" && (
        <>
          <Text size={35} weight={600}>
            Confirm the coverage lines for <div style={{ color: "#4854F4", display: "inline", fontFamily: "Assistant", fontSize: 40 }}>{localDisplayName}</div>
          </Text>
          <Text size={15} color="gray">
            It's not required, but it will make your report significantly more accurate.
          </Text>
          <Divider />
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "32px", flexWrap: "wrap", width: "90%", margin: "auto", transform: "translateY(-10px)" }}>
            { coverageLines.map(l => (
              <div
                className="button"
                style={{
                  padding: "18px",
                  borderRadius: "60px",
                  cursor: "pointer",
                  backgroundColor: !!selectedLines.find(l2 => l == l2) ? "#008000" : "#A9A9A9"
                }}
                onClick={() => onLineClick(l)}
              >
                <Text size={28} color="white" weight={600}>{l}</Text>
              </div>
            ))}
          </div>
          <div style={{ position: "sticky", bottom: "10px", right: "10px", marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
            <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => {
              if (objective == "report") {
                setPage("normal");
              } else {
                setPage("compare");
              }
            }}>
              <Text size={20} weight={600} color="#000000">
                Back
              </Text>
            </Button>
            { !loading && (
              <Button onClick={onConfirmLines1}>
                <Text size={16} weight={600} color="white">
                  { objective == "report" ? "Generate" : "Confirm Lines" }
                </Text>
              </Button>
            )}
            { loading && (
              <LoadingSpinner />
            )}
          </div>
        </>
      )}
      { page == "confirmLines2" && (
        <>
          <Text size={35} weight={600}>
            Confirm the coverage lines for <div style={{ color: "#4854F4", display: "inline", fontFamily: "Assistant", fontSize: 40 }}>{secondDocument?.name}</div>
          </Text>
          <Text size={15} color="gray">
            It's not required, but it will make your report significantly more accurate.
          </Text>
          <Divider />
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "32px", flexWrap: "wrap", width: "90%", margin: "auto", transform: "translateY(-10px)" }}>
            { coverageLines.map(l => (
              <div
                className="button"
                style={{
                  padding: "18px",
                  borderRadius: "60px",
                  cursor: "pointer",
                  backgroundColor: !!selectedLines.find(l2 => l == l2) ? "#008000" : "#A9A9A9"
                }}
                onClick={() => onLineClick(l)}
              >
                <Text size={28} color="white" weight={600}>{l}</Text>
              </div>
            ))}
          </div>
          <div style={{ position: "sticky", bottom: "10px", right: "10px", marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
            <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => {
              setPage("compare");
            }}>
              <Text size={20} weight={600} color="#000000">
                Back
              </Text>
            </Button>
            { !loading && (
              <Button onClick={onConfirmLines2}>
                <Text size={16} weight={600} color="white">
                  Generate
                </Text>
              </Button>
            )}
            { loading && (
              <LoadingSpinner />
            )}
          </div>
        </>
      )}
      { page == "additionalDocuments" && (
        <div style={{ width: "100%"}}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "center",
          }}>
            <img style={{ width: "80px", height: "80px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/left-arrow.png"} onClick={() => setPage("normal")} />
            <Text size={35} weight={600}>
              Upload Additional Documents
            </Text>
          </div>
          <Divider />
          <Space px={20} />
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "100%", height: "85%", justifyContent: "center" }}>
            <Container title="Endorsements" width="90%">
              <FileDrop documentType="endorsements" documentsLength={endorsements.length} retrieveDocuments={retrieveDocuments} category="endorsement" clientId={clientId} setErrorMessage={setErrorMessage} associatedDocumentId={document?.instanceId} setClientId={setClientId}>
                <div style={{ display: "flex", flexDirection: "row", gap: "30px", flexWrap: "wrap", width: "100%", justifyContent: endorsements.length == 0 ? "center" : undefined }}>
                  { endorsements.map(d => (
                    <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments} onClick={() => {
                      onViewAdditionalDocument(d);
                    }} />
                  ))}
                  <NewDocument documentsLength={endorsements.length} retrieveDocuments={retrieveDocuments} category="endorsement" clientId={clientId} associatedDocumentId={document?.instanceId} setErrorMessage={setErrorMessage} />
                </div>
              </FileDrop>
            </Container>
          </div>
        </div>
      )}
      { page == "viewAdditionalDocument" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
            }}>
              <img style={{ width: "80px", height: "80px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/left-arrow.png"} onClick={() => setPage("additionalDocuments")} />
              <Text size={35} weight={600}>
                {additionalDocument?.name ?? ""}
              </Text>
            </div>
            <div style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              alignItems: "center",
            }}>
              { !deleteButtonClicked && (
                <Button padding="10px" maxHeight="20px" color="white" border="1px solid red" onClick={() => setDeleteButtonClicked(true)}> 
                  <Text size={16} color="red">
                    Delete Document
                  </Text>
                </Button>
              )}
              { deleteButtonClicked && (
                <>
                  <Button padding="10px" maxHeight="20px" color="white" border="1px solid black" onClick={() => setDeleteButtonClicked(false)}> 
                    <Text size={16} color="black">
                      Cancel
                    </Text>
                  </Button>
                  { !deleting && (
                    <Button padding="10px" maxHeight="20px" color="red" onClick={deleteAdditionalDoc}> 
                      <Text size={16} color="white">
                        Delete Document
                      </Text>
                    </Button>
                  )}
                  { deleting && (
                    <LoadingSpinner />
                  )}
                </>
              )}
            </div>
          </div>
          <Divider />
          <iframe
            src={additionalDocumentUrl}
            width="100%"
            height="110%"
          />
        </>
      )}
      { page == "markdown" && (
        <MarkdownPage result={scheduleResult} setPage={setPage} />
      )}
    </Modal>
  )
};

export default PDFModal;
