import React, { useState } from 'react';

import "./Sidebar.css";

import Text from './base/Text';
import SidebarOption from './SidebarOption';

const SidebarCollapse = ({ isOpen, toggleSidebar }: { isOpen: boolean, toggleSidebar: () => void }) => {

  return (
    <div 
      style={{
        position: "absolute",
        bottom: "70px",
        left: isOpen ? "186px" : "20px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        zIndex: 10,
        width: "40px",
        height: "40px",
        border: "1px solid #B9B9B9",
        borderRadius: "40px",
        boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
        cursor: "pointer",
        transition: "left 0.3s ease"
      }}
      onClick={toggleSidebar}
    >
      <img
        src={process.env.PUBLIC_URL + "/assets/right-arrow.png"}
        style={{ width: "20px", rotate: isOpen ? "180deg" : "0deg" }}
      />
    </div>
  )

};

const Sidebar = ({ isOpen, setIsOpen, page, setPage, agencyId }: { isOpen: boolean, setIsOpen: (b: boolean) => void, page: string, setPage: (s: string) => void, agencyId: string }) => {

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    (
      <>
        <div 
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            backgroundColor: "#F7F7F7",
            width: "206px",
            height: "100%"
          }}
          className={`sidebar ${isOpen ? 'open' : 'closed'}`}
        >
          <div style={{ marginTop: "131px" }}>
            <SidebarOption text="Policies" isPage={page === "documents"} image="policy2" page="documents" setPage={setPage} isOpen={isOpen} />
            <SidebarOption text="Reports" isPage={page === "reports"} image="report2" page="reports" setPage={setPage} isOpen={isOpen} />
            <SidebarOption text="Comparisons" isPage={page === "comparisons"} image="compare" page="comparisons" setPage={setPage} isOpen={isOpen} />
            { (
                agencyId == process.env.REACT_APP_TWIN_CITIES_AGENCY_ID || 
                agencyId == process.env.REACT_APP_ISU_AGENCY_ID || 
                agencyId == process.env.REACT_APP_PORTAL_AGENCY_ID
              ) && (
                <SidebarOption text="Proposals" isPage={page === "proposals"} image="proposal" page="proposals" setPage={setPage} isOpen={isOpen} /> 
            )}
          </div>
        </div>
        <SidebarCollapse isOpen={isOpen} toggleSidebar={toggleSidebar} />
      </>
    )
  );
};

export default Sidebar;
